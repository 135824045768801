body {
  background-color: lightgreen;
}

.App {
  font-family: sans-serif;
  margin: 0 10px;
  /* text-align: center; */
  /* height: 100vh; */
}

.cookies {
  width: 98%;
  padding: 10px 0;
  border: 2px solid #005000;
  border-radius: 5px;
  position: fixed;
  bottom: 10px;
  left: 1%;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.cookies div {
  margin: 10px;
}

.exis {
  cursor: pointer;
}